import { useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { IoIosArrowDown } from 'react-icons/io';
import Link from 'next/link';
import cn from 'classnames';
import { useRouter } from 'next/router';

export const DropDownMenu = ({
  className,
  itemStyle,
  name,
  href,
  children,
  menuLinks,
  trackNavigation,
  textStyle,
  badge = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const router = useRouter();

  const onTheSameRoute = router.asPath === '/' ? false : href?.startsWith(router.asPath) || false;

  return (
    <Menu
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      as="div"
      className={'relative inline-block'}
    >
      <Menu.Button onClick={handleMouseLeave} className={cn(className)}>
        {href && !onTheSameRoute ? (
          <Link href={href} passHref as={href}>
            <a
              className={cn(
                'w-full text-center font-satoshi-medium text-sm capitalize leading-5 tracking-wide hover:bg-opacity-30 hover:text-red',
                textStyle
              )}
              target={href.startsWith('http') ? '_blank' : ''}
            >
              {badge && (
                <p className="absolute -top-4 -left-4 bg-green-cyan py-[2px] px-[4px] text-center text-[12px] font-medium capitalize leading-3 tracking-wide text-blue-midnight shadow-md shadow-green-cyan/30">
                  {badge}
                </p>
              )}

              <span className="flex items-center gap-x-2 font-medium">
                {name}

                {name === 'Courses' && (
                  <IoIosArrowDown
                    className={`duration-200 ease-in-out ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                    size={22}
                  />
                )}
              </span>
            </a>
          </Link>
        ) : (
          <p
            className={cn(
              'w-full cursor-pointer text-center font-satoshi-medium text-sm capitalize leading-5 tracking-wide hover:bg-opacity-30 hover:text-red',
              textStyle
            )}
          >
            <span className="flex items-center gap-x-2">
              {name}
              {name === 'Courses' && (
                <IoIosArrowDown
                  className={`duration-200 ease-in-out ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                  size={22}
                />
              )}
            </span>
          </p>
        )}
      </Menu.Button>

      {(menuLinks || children) && isOpen && (
        <div
          className="fixed top-16 left-0 right-0 z-50 h-screen w-full bg-black/10 backdrop-blur-sm"
          onMouseEnter={handleMouseLeave}
        />
      )}

      <Transition
        show={isOpen}
        as={'div'}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className={'relative right-1/2 !z-[115]'}
      >
        <Menu.Items
          static
          className={`absolute top-0 z-20 w-fit transform whitespace-nowrap ${itemStyle}`}
        >
          {menuLinks && (
            <>
              <div
                className="mt-4 origin-top-right divide-y divide-gray-light/50 rounded-md bg-white p-[4px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                onClick={() => setIsOpen(false)}
              >
                {menuLinks?.map(({ name, title, link, slug }) => {
                  if (link?.startsWith('http')) {
                    return (
                      <Menu.Item key={name ?? title}>
                        {({ active }) => (
                          <button
                            className={cn(
                              'group flex w-full justify-center font-satoshi-medium text-sm font-medium  capitalize tracking-wider',
                              { 'bg-red/5 text-red': active, 'text-gray-900': !active }
                            )}
                            onClick={trackNavigation}
                          >
                            <a
                              key={name ?? title}
                              href={link}
                              target="_blank"
                              className="w-full px-4 py-2"
                            >
                              {name ?? title}
                            </a>
                          </button>
                        )}
                      </Menu.Item>
                    );
                  }

                  return (
                    <Link
                      passHref
                      key={name ?? title}
                      href={link ?? `/${slug}`}
                      as={link ?? `/${slug}`}
                    >
                      <Menu.Item key={name ?? title}>
                        {({ active }) => (
                          <a
                            className={`${
                              active ? 'bg-red/5 text-red' : 'text-gray-900'
                            } group flex  w-full  justify-center px-4 py-2 font-satoshi-medium text-sm font-medium  capitalize tracking-wider`}
                            onClick={trackNavigation}
                          >
                            {name ?? title}
                          </a>
                        )}
                      </Menu.Item>
                    </Link>
                  );
                })}
              </div>
            </>
          )}

          {children && (
            <div
              className="mt-4 origin-top-right divide-y divide-gray-light/50 rounded-md px-1 py-1 ring-1 ring-black ring-opacity-5 focus:outline-none"
              onClick={() => setIsOpen(false)}
            >
              {children}
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropDownMenu;
