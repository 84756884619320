export const STUDENT_SIGNUP = 'https://grow.almabetter.com/auth/signup';

export const COACH_SIGNUP = 'https://coach.almabetter.com/auth/signup';

export const REQUEST_CALLBACK = 'https://grow.almabetter.com/request-callback';

export const PLAN_TYPE = {
  PG_CERTIFICATION: 'pg_certification',
  MASTERS_DEGREE: 'masters_degree',
  UG_COURSE: 'ug_course',
};

export const COURSE_TYPE = {
  DATA_SCIENCE: 'data-science',
  WEB3: 'web3',
  MASTER_COMPUTER_SCIENCE: 'ms-in-computer-science-and-engineering',
  MASTER_DATA_SCIENCE: 'ms-in-data-science-and-analytics',
  UG_DIPLOMA_COMPUTER_SCIENCE: 'ug-diploma-in-computer-science',
};

export const GOAL_TYPE_MAPPINGS = {
  [COURSE_TYPE.DATA_SCIENCE]: {
    [PLAN_TYPE.PG_CERTIFICATION]: 'data-science',
    [PLAN_TYPE.MASTERS_DEGREE]: 'masters-ds',
  },
  data_science: {
    [PLAN_TYPE.PG_CERTIFICATION]: 'data-science',
    [PLAN_TYPE.MASTERS_DEGREE]: 'masters-ds',
  },
  [COURSE_TYPE.WEB3]: {
    [PLAN_TYPE.PG_CERTIFICATION]: 'web3',
    [PLAN_TYPE.MASTERS_DEGREE]: 'masters-cs',
  },
};

export const COURSES_SLUGS = {
  DATA_SCIENCE: 'full-stack-data-science',
  WEB3: 'full-stack-web-development',
  MASTER_COMPUTER_SCIENCE: 'masters-in-computer-science-cloud-computing',
  MASTER_DATA_SCIENCE: 'masters-in-computer-science-data-science-ai',
  UG_DIPLOMA_COMPUTER_SCIENCE: 'ug-diploma-in-computer-science',
};

export const COURSE_TYPE_NAMES_MAPPING = {
  [COURSE_TYPE.DATA_SCIENCE]: 'Full Stack Data Science',
  [COURSE_TYPE.WEB3]: 'Full Stack Web Development with Web3',
  [COURSE_TYPE.MASTER_COMPUTER_SCIENCE]: "Master's degree in Computer Science",
  [COURSE_TYPE.MASTER_DATA_SCIENCE]: "Master's degree in Data Science",
};

export const COURSE_TYPE_SLUG_MAPPING = {
  [COURSE_TYPE.DATA_SCIENCE]: 'full-stack-data-science',
  [COURSE_TYPE.WEB3]: 'full-stack-web-development',
  [COURSE_TYPE.MASTER_DATA_SCIENCE]: 'masters-in-computer-science-data-science-ai',
  [COURSE_TYPE.MASTER_COMPUTER_SCIENCE]: 'masters-in-computer-science-cloud-computing',
};

export const NEW_COURSE_SLUG = {
  'full-stack-data-science-course': COURSES_SLUGS.DATA_SCIENCE,
  'full-stack-developer-course': COURSES_SLUGS.WEB3,
};

export const GOAL_TYPE = {
  [COURSES_SLUGS.DATA_SCIENCE]: 'dataScience',
  [COURSES_SLUGS.WEB3]: COURSE_TYPE.WEB3,
  [COURSES_SLUGS.MASTER_DATA_SCIENCE]: 'dataScience',
  [COURSES_SLUGS.MASTER_COMPUTER_SCIENCE]: COURSE_TYPE.WEB3,
};

export const CODING_LANGUAGE = ['javascript', 'python', 'sql', 'java'];

export const LANG_OPTION = {
  python: 'Python 3',
  sql: 'SQLite 3',
  javascript: 'Javascript',
  react: 'React.js',
  java: 'Java',
};

export const intialCodeOfLang = {
  sql: '-- There are 11 tables in the sample database.\n-- Such as: - Employees, Customers, Invoices, Invoice Items (invoice_items), Artists, Albums, Genres, Media Types (media_types), Tracks, Playlists & Playlist Track (playlist_track).\n\nSELECT * FROM Employees;',
  javascript: `console.log("Hello World!");`,
  python: `print("Hello World!")`,
  java: `/*
  * WARNING: Please ensure that your main class name is "Main".
  * Modifying the main class name may cause compilation errors.
*/

class Main {
    public static void main(String[] args) {
        System.out.println("Hello, World!"); 
    }
}`,
};

export const LANG_MAP = {
  [LANG_OPTION.python]: 'python',
  [LANG_OPTION.javascript]: 'javascript',
  [LANG_OPTION.sql]: 'sql',
  [LANG_OPTION.react]: 'react',
  [LANG_OPTION.java]: 'java',
};

export const RCB_OPTION_LIST = {
  'data-science': 'Certification in Full Stack Data Science and AI',
  web3: 'Certification in Full Stack Web Development',
  'ms-in-data-science-and-analytics': 'Masters in CS: Data Science and Artificial Intelligence',
  'ms-in-computer-science-and-engineering': 'Masters in Computer Science: Cloud Computing',
};

export const WA_LINK = 'https://wa.me/919902446768';

const yearOptions = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = currentYear - 15; year <= currentYear + 5; year++) {
    years.push({ label: year, value: year });
  }
  return years;
};
export const globalFormFields = (courseInterestOptions) => [
  {
    name: 'name',
    type: 'text',
    placeholder: 'Enter Full Name',
  },
  {
    name: 'email',
    type: 'email',
    placeholder: 'Enter Your Email',
  },
  {
    name: 'courseInterest',
    type: 'select',
    placeholder: 'Course Interest',
    options: courseInterestOptions,
  },
  {
    name: 'graduationYear',
    type: 'select',
    placeholder: 'Graduation Year',
    options: yearOptions(),
  },
  {
    name: 'userType',
    type: 'radio',
    label: 'Are you currently working?',
    options: [
      { value: 'working', label: 'Yes' },
      { value: 'not-working', label: 'No' },
    ],
  },
  {
    name: 'background',
    type: 'radio',
    label: 'Are you currently pursuing an engineering course or degree?',
    options: [
      { value: 'engineering', label: 'Yes' },
      { value: 'non-engineering', label: 'No' },
    ],
  },
  {
    name: 'phone',
    type: 'tel',
    placeholder: 'Mobile Number',
  },
];
